import React                from "react"
import { Route }            from "react-router-dom";

import AboutMe              from "./pages/AboutMe";
import Contact              from "./pages/Contact";
import Bio                  from "./pages/Bio";
import Video                from "./pages/Video";
import Literature           from "./components/Literature";
import Exhibitions          from "./pages/Exhibitions";
import News                 from "./pages/News";
import Work                 from "./pages/Work";
import CookiesPolicy        from "./components/CookiesPolicy";
import Home                 from "./pages/Home";

const routes = ({
  lang,
  videos,
  literature,
  exhibitions,
  news,
  galleryCategories,
  photos,
  toggleLightbox,
  translatedLinks
}) => (
  <>
    <Route path="/" exact>
      <Home
        allPhotos={photos}
        galleryCategories={galleryCategories}
        toggleLightbox={toggleLightbox}
        exhibitions={exhibitions}
        videos={videos}
        news={news}
        translatedLinks={translatedLinks}
        lang={lang}
      />
    </Route>

    <Route path="/platero"><AboutMe /></Route>
    <Route path="/contact"><Contact /></Route>

    <Route path="/bio"><Bio lang={lang} /></Route>

    <Route path="/video/:category"><Video lang={lang} videos={videos} /></Route>
    <Route exact path="/video"><Video lang={lang} videos={videos} /></Route>

    <Route path="/literature"><Literature title="Literature" lang="en" literature={literature} /></Route>
    <Route path="/literatura"><Literature title="Literatura" lang={lang} literature={literature} /></Route>

    <Route path="/exhibitions/:category"><Exhibitions title="Exhibitions" lang="en" horizontal exhibitions={exhibitions} /></Route>
    <Route path="/exhibitions" exact><Exhibitions title="Exhibitions" lang="en" horizontal exhibitions={exhibitions} /></Route>

    <Route path="/exposiciones/:category"><Exhibitions title="Exposiciones" lang="es" horizontal exhibitions={exhibitions} /></Route>
    <Route path="/exposiciones" exact><Exhibitions title="Exposiciones" lang="es" horizontal exhibitions={exhibitions} /></Route>

    <Route path="/news/:category"><News news={news} title="News" lang="en" /></Route>
    <Route path="/news" exact><News news={news} title="News" lang="en" /></Route>

    <Route path="/noticias/:category"><News news={news} title="Noticias" lang="es" /></Route>
    <Route path="/noticias" exact><News news={news} title="Noticias" lang="es" /></Route>

    <Route path="/work/:category">
      <Work
        galleryCategories={galleryCategories}
        allPhotos={photos}
        toggleLightbox={toggleLightbox}
        lang="en"
        title="Work"
      />
    </Route>
    <Route exact path="/work">
      <Work
        galleryCategories={galleryCategories}
        allPhotos={photos}
        toggleLightbox={toggleLightbox}
        translatedLinks={translatedLinks}
        all
        lang="en"
        title="Work"
      />
    </Route>
    <Route path="/obras/:category">
      <Work
        galleryCategories={galleryCategories}
        allPhotos={photos}
        toggleLightbox={toggleLightbox}
        lang="es"
        title="Obras"
      />
    </Route>
    <Route exact path="/obras">
      <Work
        galleryCategories={galleryCategories}
        allPhotos={photos}
        toggleLightbox={toggleLightbox}
        translatedLinks={translatedLinks}
        all
        lang="es"
        title="Obras"
      />
    </Route>
    <Route path="/cookies-policy"><CookiesPolicy lang={lang} /></Route>
  </>
)

export default routes

