import React, { useState }             from 'react'
import styled                          from 'styled-components'


import RolloverWrapper                 from './RolloverWrapper'
import HamburgerMenu                   from './HamburgerMenu'

import { fetchData, mapFetchedLinks }                   from 'functions'

import { HorizontalLinksWrapper, StyledLink }    from 'styledComponents'

const MainWrapper = styled.div`
  width: 100%;
  margin: 2px auto;
`;

const Links = (props) => {

  return (
    <MainWrapper>
      <HorizontalLinksWrapper>
        {
          props.translatedLinks.map(linkElement => {
            if(linkElement.inner){ 
              return (
                <RolloverWrapper topElement={linkElement}>
                  {linkElement.inner.map(innerLinkElement => <StyledLink activeClassName="active-link" to={`${linkElement.path}${innerLinkElement.path}`}>{innerLinkElement.label}</StyledLink>)}
                </RolloverWrapper>
              )
            }
            return <StyledLink onClick={() => {window.scrollTo(0,0)}} activeClassName="active-link" to={linkElement.path}>{linkElement.label}</StyledLink>
          })
        }
      </HorizontalLinksWrapper>
      <HamburgerMenu changeLanguage={props.changeLanguage} lang={props.lang} toggleOverflow={props.toggleOverflow} links={props.translatedLinks} />
    </MainWrapper>

  )
}

export default Links