import React, { Component } from 'react'
import styled from 'styled-components'
import Progress from "components/Progress"

import { withRouter} from "react-router-dom"

import media from 'styledComponents/media'

import { fetchData, mapFetchedNews } from 'functions'
import { LiteratureParagraph, LiteratureTitle } from 'styledComponents'
import Heading from 'components/Heading'

const StyledImage = styled.img`
  width: 100%;
`

const NewsTitle = styled(LiteratureTitle)`
  font-size: 28px;
  ${props => props.length < 60 && "max-width: 300px"};
  margin-bottom: 15px;
`

const NewsLayout = styled.div`
  margin: 10px 0;
  /* ${media.mobile`
    margin: 10px 0;
  `} */
`

const NewsCard = styled.div`
  position: relative;
 
  display: block;
  ${media.mobile`
    display: flex;
  `}

  &:not(:last-of-type){
    margin: 30px auto;
    border-bottom: 1px solid #444;
    padding-bottom: 30px;
  }
`

const PostDate = styled.p`
  font-weight: 600;
  color: #a3a9ac;
  letter-spacing: 0.1em;
  font-size: 14px;
  margin-top: 5px;
`

const Title = styled.h2`
  color: #222;
`

const CardSection = styled.div`
  width: 100%;
  ${media.mobile`
    width: 50%;
  `}
  position: relative;
`

const NewsBody = styled.div`
  margin: 20px 0;
  font-weight: 300;
  font-size: 17px;

  ul li{
    list-style: disc inside;
  }
  ol li{
    list-style: decimal inside;
  }
  a{
    font-weight: 600;
  }
  a:hover{
    text-decoration: underline;
  }
  p{
    margin: 15px 0;
  }
`

class News extends Component {
  state = {
    isLoaded: false,
    news: this.props.news || []
  }

  componentDidMount = async () => {
    if(this.state.news.length === 0){
      const response = await fetchData('https://dev.platero.eu/wp-json/wp/v2/news/')
      const news = mapFetchedNews(response)
      this.setState({
        news
      })
    }
  }

  render(){
    const { title, lang } = this.props;
    const category = this.props.match.params.category;
    const news = category ? this.state.news.filter(el => el.id === +category) : this.state.news;
    if(this.state.news.length === 0) {
      return <Progress />
    }
    return (
      <>
        <Heading>{title}</Heading>
        <NewsLayout>
          {
            news.map(el => {
              const newsDate = new Date(el.date)
              const day = newsDate.getDate()
              const month = newsDate.getMonth() + 1
              const year = newsDate.getFullYear().toString()
              return (
                <NewsCard>
                  <CardSection style={{marginRight: "20px"}}>
                    <NewsTitle length={el[lang].title && el[lang].title.length}>{el[lang].title}</NewsTitle>
                    <PostDate>{day.toString().length === 1 ? "0" + day : day}/{month.toString().length === 1 ? "0" + month : month}/{year}</PostDate>
                    <NewsBody dangerouslySetInnerHTML={{__html: el[lang].content}} />
                  </CardSection>
                  <CardSection><StyledImage src={el.sizes.med} /></CardSection>
                </NewsCard>
            )})
          }
        </NewsLayout>
      </>
    )
  }
}

export default withRouter(News)