import React from 'react'
import styled from 'styled-components'
import rightArrow from 'assets/keyboard_arrow_right-24px.svg'

import media from 'styledComponents/media'

import { Link } from 'react-router-dom'

const StyledImage = styled.img`
  fill: red;

`
const Paragraph = styled(Link)`
  font-family: 'Barlow', sans-serif;
  text-transform: uppercase;
  font-weight: 600;
  cursor: pointer;
  z-index: 1;
  display: ${props => props.categoriesCount > 4 ? "block" : "none"}
  ${media.desktop`
    display: ${props => props.categoriesCount > 6 ? "block" : "none"}
  `}
  
  
  :after{
    position: relative;
    top: 6px;
    content: url(${rightArrow});
    transition: margin-left .1s ease-in-out;
  }
  :hover{
    :after{
      margin-left: 5px;
      
    }
  }

`


const ShowMoreButton = ({ link, children, categoriesCount }) => {
  console.log('categoriesCount', categoriesCount)

  return (
    <>
      <Paragraph categoriesCount={categoriesCount} onClick={() => window.scrollTo(0,0)} to={link}>{children}</Paragraph>
    </>
  )
}

export default ShowMoreButton
