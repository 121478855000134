import React from 'react'
import styled from 'styled-components'
import media from 'styledComponents/media'

const StyledImage = styled.img`
  width: 100%;
  ${media.tablet`
    width: 40vw;
  `}
`

const StyledTitle = styled.h2`
  font-weight: 600;
  color: #333d47;
  letter-spacing: 0.1em;
  font-size: 18px;
  line-height: 1.5;
  ${props => props.bigTitle && `
    font-size: 28px;
    max-width: 50%;
    line-height: 1.2;
    margin-bottom: 17px;
  `}
  
`

const CityAndDate = styled.p`
  font-weight: 600;
  color: ${props => props.bigTitle ? "#a3a9ac" : "#e11282"};
  letter-spacing: 0.1em;
  font-size: ${props => props.bigTitle ? "15px" : "14px"};
`

const Description = styled(CityAndDate)`
  margin-top: 10px;
  color: #a3a9ac;
  color: #5A5857;
  font-weight: 500;
  font-size: 17px;
  ${props => props.bigTitle && `
    text-transform: none;
    letter-spacing: 0;
    margin-top: 20px;
    font-weight: 300;
  `}

  p{
    margin: 10px 0;
  }
`

const StyledDiv = styled.div`
  z-index: 1;
  display: flex;
  flex-direction: column-reverse;
  &:not(:last-of-type){
    
    border-bottom: 1px solid #5A5857;;
    padding-bottom: 30px;
  }
  ${media.tablet`
    flex-direction: row;
  `}
`

const TextWrapper = styled.div`
  position: static;
  text-transform: uppercase;
  top: 100%;
  width: 100%;
  margin-left: 0;
  margin-bottom: 30px
  margin-top: -5px;
  ${media.tablet`
    margin-left: 30px;
    margin-bottom: 0;
  `};
`

const CardContainer = styled.div`
  font-family: 'Barlow', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: relative;
  width: 100%;
  padding-bottom: 67%;
  background-repeat: no-repeat;
  background-image: url(${props => props.labelPhoto}); 
  background-size: cover;
  background-position: center;
  z-index: 1;

  :after{
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background: black;
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }

  :before{
    position: absolute;
    content: "${props => props.name}";
    z-index: 1;
    opacity: 0;
    transition: all .2s ease-in-out;
    color: white;
    font-size: 30px;
    text-transform: uppercase;
    top: 50%;
    transform: translateY(-50%);
  }

  :hover{
    :after{
      content: "";
      opacity: .5;
    }
    :before{
      opacity: 1;
    }
  }
`

const MainCard = ({ name, labelPhoto, place, city, date, description, horizontal, bigTitle}) => {
  const isExhibition = !!place && !!city
  return (
    <StyledDiv horizontal={horizontal}>
      <div><StyledImage src={labelPhoto} name={name} /></div>
      <TextWrapper>
        <StyledTitle bigTitle={bigTitle}>{place}</StyledTitle>
        <CityAndDate bigTitle={bigTitle}>{city} / {date}</CityAndDate>
        <Description bigTitle={bigTitle} dangerouslySetInnerHTML={{__html: description}} />
      </TextWrapper>
    </StyledDiv>
  )
}

export default MainCard
