import React from "react"
import styled from "styled-components"
import CircularProgress from "@material-ui/core/CircularProgress";

const ProgressWrapper = styled.div`
  width: 100%;
  min-height: 40vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Progress = () => (
  <ProgressWrapper>
    <CircularProgress size={80} thickness={2} />
  </ProgressWrapper>
)

export default Progress