import React from 'react'
import styled from 'styled-components'

import Heading from 'components/Heading'

import media from 'styledComponents/media'

const Wrapper = styled.div`
  margin: 50px 10px;
  ${media.mobile`
    margin: 50px 30px;
  `}
  font-weight: 300;
  ${media.tablet`
    display: flex;
    justify-content: space-between;
  `}
`

const StyledHeader = styled.h2`
  margin-bottom: 30px;
`
const StyledParagraph = styled.p`
  margin-bottom: 30px;
  line-height: 1.5;
`

const StyledInput = styled.input`
  display: block;
  font-size: 16px;
  padding: 5px 10px;
  margin: 20px 0;
  border: none;
  border-bottom: 1px solid #5A5857;
  ${props => props.email && 'min-width: 250px'};
  font-weight: 300;
`

const InputWrapper = styled.div`
  max-width: 350px;
  margin-right: 40px;
`

const ContactWrapper = styled.div`
  
  
  ${media.smallest`
    display: flex;
    justify-content: space-between;
  `}

  ${media.tablet`
    margin-top: 5px;
  `}
`

const ContactElement = styled.div`
  margin-bottom: 15px;
  h4{
    font-size: 18px;
    margin-bottom: 4px;
  }
  p{
    font-size: 15px;
  }
`

const ContactColumn = styled.div`
 ${media.tablet`
    display: flex;
    flex-direction: column-reverse;
  `}
`

const StyledImage = styled.img`
  max-width: 100%;
`

const InputButton = styled.button`
  margin-top: 20px;
  padding: 5px 10px;
  background-color: white;
  border: 1px solid #5A5857;
  color: #5A5857;
  font-size: 16px;
`

const TextWrapper = styled.div`
  margin-top: 40px;
`

const Contact = () => (
  <>
    <Heading>Contact</Heading>
    <Wrapper>
      <InputWrapper>
        <StyledHeader>Get to know Platero better!</StyledHeader>
        {/* <StyledParagraph>To get monthly insights into Platero workshop and paintings availability please subscribe below.</StyledParagraph>
        <StyledInput placeholder="First name" />
        <StyledInput email placeholder="Email" />
        <InputButton>Join now</InputButton> */}
        <ContactWrapper>
          <ContactColumn>
            <TextWrapper>
              <ContactElement>
                <h4>Workshop</h4>
                <p>ul. Dzielna 7</p>
                <p>00-154 Warszawa</p>
                <p>Polska</p>
              </ContactElement>
              <ContactElement>
                <h4>Web</h4>
                <p>www.platero.eu</p>
              </ContactElement>
              <ContactElement>
                <h4>E-mail</h4>
                <p>info@platero.eu</p>
              </ContactElement>
            </TextWrapper>
          </ContactColumn>
        </ContactWrapper>
        
      </InputWrapper>
      <div><StyledImage src="photos/platero-map.jpg" /></div>
      {/* <ContactWrapper>
        <ContactColumn>
          <TextWrapper>
            <ContactElement>
              <h4>Workshop</h4>
              <p>ul. Dzielna 7</p>
              <p>00-154 Warszawa</p>
              <p>Polska</p>
            </ContactElement>
            <ContactElement>
              <h4>Web</h4>
              <p>www.platero.eu</p>
            </ContactElement>
            <ContactElement>
              <h4>E-mail</h4>
              <p>info@platero.eu</p>
            </ContactElement>
          </TextWrapper>
          <img alt="" src="photos/Logo-lateral-Platero.jpg" />
        </ContactColumn>
      </ContactWrapper> */}
      
    </Wrapper>
  </>
)

export default Contact