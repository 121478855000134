import React                      from 'react'
import styled                     from 'styled-components'
import { Link, NavLink }          from 'react-router-dom'
import links                      from 'links'

import Links                      from './Links'

import media                      from 'styledComponents/media'
import logo                       from 'assets/Platero_Logo.svg'

const MainWrapper = styled.div`
  display: flex;
  position: relative;
  align-items: flex-end;
  justify-content: space-between;
  background: white;
  z-index: 2;
  padding: 10px 20px 20px;
  ${media.mobile`
    padding: 10px 30px 20px 30px;
  `}
  max-width: 1200px;
  margin: 0 auto;
`;

const HorizontalLinks = styled.div`
  margin-top: 20px;
  width: 100%;
`;

const Languages = styled.div`
  cursor: pointer;
  display: none;
  text-align: right;
  font-size: 14px;
  line-height: 1.6;
  max-width: 70px;
  ${media[1056]`
    display: flex;
  `}

   :hover{
     > a{
      transform: translateX(-70px);
      max-width:140px;
     }
  }
`;

const LanguageElement = styled(NavLink)`
  padding: 0 5px;
  letter-spacing: 0.1em;
  background: white;
  ${props => props.transformLength && `
    transform: translateX(${props.transformLength});
  `}
  transition: transform .2s ease-in-out;
`

const generateLink = (loc, splitedPathname, targetLanguage) => {

  const route = `/${splitedPathname[1]}`
  const subPage = splitedPathname.length > 2 ? `/${splitedPathname[2]}` : ""
  let routeLanguage;
  const getLinkLanguage = links.forEach(el => {
    const flattenElement = Object.entries(el);
    const langObj = flattenElement.find(innerElement => innerElement[1].path === route);
    if(langObj){
      routeLanguage = langObj[0]
    }
  });

  if(!routeLanguage) return "/cookies-policy";
  const linkObject = links.find(el => el[routeLanguage].path === route)

  return `${linkObject[targetLanguage].path}${subPage}`
};


const Header = ({ toggleOverflow, changeLanguage, translatedLinks, lang }) => {
  return (
    <MainWrapper>
      <div><Link to="/" onClick={() => window.scroll(0,0)}><img alt="" src={logo} width="150px" /></Link></div>
      <HorizontalLinks>
        <Links lang={lang} changeLanguage={changeLanguage} translatedLinks={translatedLinks} toggleOverflow={toggleOverflow} />
      </HorizontalLinks>
      
      <Languages>
        <LanguageElement
          isActive={() => lang === "en"}
          onClick={() => {changeLanguage("en")}} 
          transformLength="0"
          to={loc => {
            const splitedPathname = loc.pathname.split("/")
            const targetLink = splitedPathname[1].length > 1 ? generateLink(loc, splitedPathname, "en") : "/";
            return targetLink
          }}
        >ENGLISH</LanguageElement>
        <LanguageElement
          isActive={() => lang === "es"}
          transformLength="-70px"
          onClick={() => {changeLanguage("es")}} 
          to={loc => {
            const splitedPathname = loc.pathname.split("/")
            const targetLink = splitedPathname[1].length > 1 ? generateLink(loc, splitedPathname, "es") : "/"
            return targetLink
          }}
        >SPANISH</LanguageElement>
        {/* <LanguageElement
          isActive={() => lang === "pl"}
          transformLength="-140px"
          onClick={() => {changeLanguage("pl")}} 
          to={loc => {
            const splitedPathname = loc.pathname.split("/")
            const targetLink = splitedPathname[1].length > 1 ? generateLink(loc, splitedPathname, "pl") : "/"
            return targetLink
          }}
        >POLISH</LanguageElement> */}
      </Languages>
    </MainWrapper>
  )
}

export default Header