import React, { Component } from 'react'
import styled from 'styled-components'
import { withRouter } from 'react-router-dom'
import media from 'styledComponents/media'
import ExhibitionCard from 'components/ExhibitionCard'

import Heading from 'components/Heading'
import {fetchData, mapFetchedExhibitions} from "../functions";

const CategoriesWrapper = styled.div`
  display: inline-grid;
  width: calc(100%);
  grid-template-columns: 1fr;
  ${media.mobile`
    grid-template-columns: ${props => props.horizontal ? "1fr" : "1fr 1fr"};
  `}
  grid-gap: 30px;
  margin-bottom: 40px;

`

class GalleryCategories extends Component {
  state = {
    exhibitions: this.props.exhibitions || []
  };

  componentDidMount = async () => {
    if(this.state.exhibitions.length === 0){
      const response = await fetchData('https://dev.platero.eu/wp-json/wp/v2/exhibitions/')
      const unsortedExhibitions = mapFetchedExhibitions(response);
      const exhibitions = unsortedExhibitions
        .sort((a,b) => b.sortValue - a.sortValue)
        .sort((a,b) => a.order - b.order);

      this.setState({
        exhibitions
      })
    }
  };

  render(){
    const category = this.props.match.params.category;
    const { home, horizontal, title, lang } = this.props;
    const { exhibitions } = this.state;

    const isMoreContent = home && exhibitions.length > 4;
    const filteredExhibitions = isMoreContent ? exhibitions.slice(0, 4) : exhibitions;
    const specificCategory = category && exhibitions.filter(el => el.id === +category);

    return (
        category ?
        <>
          <Heading>{title}</Heading>
          <CategoriesWrapper horizontal={horizontal}>
            {
              specificCategory.map(el =>
                <ExhibitionCard
                  labelPhoto={el.label_photos.med}
                  name={el.place_of_exhibition_en}
                  place={el[lang].place}
                  city={el[lang].city}
                  date={el.date_of_exhibition}
                  description={el[lang].description}
                  horizontal={horizontal}
                  trimDescription={home && el.description_en.length > 300}
                  bigTitle
                />)
            }
          </CategoriesWrapper>
        </>
      :
        <>
          <Heading>{title}</Heading>
          <CategoriesWrapper horizontal={horizontal}>
            {
              filteredExhibitions.map(el =>
                <ExhibitionCard
                  labelPhoto={el.label_photos.med}
                  name={el[lang].place}
                  place={el[lang].place}
                  city={el[lang].city}
                  date={el.date_of_exhibition}
                  description={el[lang].description}
                  horizontal={horizontal}
                  trimDescription={home && el.description_en.length > 300}
                  bigTitle
                />)
            }
          </CategoriesWrapper>
        </>
    )

  }
}

export default withRouter(GalleryCategories);
