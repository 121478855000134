import React, { useState } from 'react'
import styled from 'styled-components'

import Carousel from 'components/Carousel'
import PhotosGrid from 'components/PhotosGrid'
import Heading from 'components/Heading'

import media from 'styledComponents/media'

const TextWrapper = styled.div`
  margin: 30px 0;
  
  p{
    margin: 0 0 20px;
    font-weight: 300;
    font-size: 17px;
  }
`;

const ColumnsWrapper = styled.div`
  ${media.tablet`
    column-count: 2;
    column-gap: 60px;  
  `} 
`;

const Gallery = ({ toggleLightbox, galleryCategories, allPhotos, id, lang }) => {
  const [isCarouselOpen, toggleCarousel] = useState(false);
  const [pickedPhotoIndex, assignPhotoIndex] = useState(0);
  
  console.log('galleryCategories', galleryCategories)
  console.log('allPhotos', allPhotos)
  
  const openLightbox = (index) => {
    toggleLightbox()
    toggleCarousel(true)
    assignPhotoIndex(index)
  }

  const closeLightbox = () => {
    toggleCarousel(false)
    toggleLightbox()
  }

  if(galleryCategories.length === 0 || allPhotos.length === 0) return null;

  const category = galleryCategories.find(el => el.id == id).slug;

  const pageId = category ? galleryCategories.find(link => link.slug === category).id : null;
  
  const photosForLightbox = allPhotos
    .map(el => ({...el, src: el.fullURL}))
    .filter(photo => category ? photo.category === pageId : true )
    .sort((a,b) => a.order - b.order);

  const galleryCategory = galleryCategories.find(galleryCategory => galleryCategory.slug === category)
 
  const selectedPhotos = category && allPhotos.filter(photo => photo.category === galleryCategory.id)
  const photosForGallery = category && selectedPhotos
    .map(({ mediumURL, width, height, category, thumbnail, order }) => ({width, height, category, src: mediumURL, thumbnail, order}))
    .filter(photo => category ? photo.category === pageId : true )
    .sort((a,b) => a.order - b.order);

  const onNextClick = () => {assignPhotoIndex(pickedPhotoIndex >= photosForLightbox.length - 1 ? 0 : pickedPhotoIndex + 1)}
  const onPrevClick = () => {assignPhotoIndex(pickedPhotoIndex >= 0 ? pickedPhotoIndex - 1 : photosForLightbox.length - 2)}

  if(category){
    return (
      <section>
        {!isCarouselOpen && 
          <>
            <Heading>{galleryCategory[lang].title}</Heading>
            <TextWrapper>
              <ColumnsWrapper dangerouslySetInnerHTML={{__html: galleryCategory[lang].description}}>
              </ColumnsWrapper>
            </TextWrapper>
            <div>
              <PhotosGrid specificWork photos={photosForGallery} onOpen={openLightbox} />
            </div>
          </>
        }
        {isCarouselOpen && <Carousel
            selected={pickedPhotoIndex}
            isOpen={isCarouselOpen} 
            closeCarousel={closeLightbox}
            photos={photosForLightbox}
            onPrevClick={onPrevClick}
            onNextClick={onNextClick}
            assignPhotoIndex={assignPhotoIndex}
            lang={lang}
          />}
      </section>
 
    )
  }
};

export default Gallery