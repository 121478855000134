import React, { Component } from 'react'
import styled from 'styled-components'
import media from 'styledComponents/media'
import LiteratureCard from './LiteratureCard'

import { Link } from 'react-router-dom'

import Heading from 'components/Heading'
import {fetchData, mapFetchedLiterature} from "../functions";

const CategoriesWrapper = styled.div`
  
  ${media.full`
    display: flex;
  `}

`

class Literature extends Component {
  state = {
    literature: this.props.literature || []
  };

  componentDidMount = async () => {
    const response = await fetchData('https://dev.platero.eu/wp-json/wp/v2/literature/');
    const literature = mapFetchedLiterature(response);
    this.setState({ literature })
  };

  render(){
    const { horizontal, title, lang } = this.props;
    const { literature } = this.state;
    return (
      <>
        <Heading>{title}</Heading>
        <CategoriesWrapper horizontal={horizontal}>
          {
            literature.map(el =>
              <LiteratureCard
               {...el}
               lang={lang}
              />)
          }
        </CategoriesWrapper>
      </>
    )
  }
}

export default Literature
