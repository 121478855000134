import React, { useState, useRef, Component }             from 'react'
import styled                          from 'styled-components'
import { Link, NavLink }                        from 'react-router-dom'

import media                           from 'styledComponents/media'

import VerticalRolloverWrapper         from './VerticalRolloverWrapper'
import Hamburger                       from './Hamburger'

import links                           from 'links'

const LINK_HEIGHT = 60

const StyledLink = styled(Link)`
  display: block;
  color: #222;
  text-decoration: none;
  font-weight: 100;
  padding: 15px 20px;
  ${media.mobile`
    padding: 15px 30px;
  `}
  background: white;
 
  :hover{
    background: #eee;
  }
 
  ${media.desktop`
    margin: 0 30px;
    font-size: 16px;
  `}
`

const LanguageLinkWrapper = styled.div`
  display: flex;
  justify-content: space-evenly;

`

const StyledSpan = styled.span`
  display: block;
  color: #222;
  text-decoration: none;
  font-weight: 100;
  padding: 15px;
  background: white;
 
  :hover{
    background: #eee;
  }
 
  ${media.desktop`
    margin: 0 30px;
    font-size: 16px;
  `}
`

const LanguageElement = styled(NavLink)`
  display: block;
  color: #222;
  text-decoration: none;
  font-weight: 100;
  padding: 15px;
  background: white;
  width: 50%;
  text-align: center;
  font-weight: ${props => props.selected ? 'normal' : 100};
 
  :hover{
    background: #eee;
  }
 
  ${media.desktop`
    margin: 0 30px;
    font-size: 16px;
  `}
`

const MainWrapper = styled.div`
  width: 100%;
  z-index: 10;
`
const HamburgerMenuWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  /* transform: translateX(-40px); */
`

const HamburgerLinksContainer = styled.div`
  font-size: 24px;
  width: 100vw;
  height: ${props => props.containerHeight && props.containerHeight + "px"};
  overflow: auto;
  position: fixed;
  background: white;
  top: 90px;
  left: 0;
  opacity: ${props => props.isOpen ? 1 : 0};
  transition: 0.4s all ease-out;
  clip-path: ${props => props.isOpen ? 
    `polygon(0 0, 100% 0, 100% 100%, 0 100%)`
  : 
    "polygon(0 0, 100% 0, 100% 0, 0 0)"};

  max-height: ${props => props.isOpen ? window.innerHeight - 90 + "px" : 0};

  margin-bottom: 50px;

  > ${StyledLink}{border-bottom: solid 1px #ddd;}
  > div {border-bottom: solid 1px #ddd;}
`

const generateLink = (loc, splitedPathname, targetLanguage) => {

  const route = `/${splitedPathname[1]}`
  const subPage = splitedPathname.length > 2 ? `/${splitedPathname[2]}` : ""
  let routeLanguage;
  const getLinkLanguage = links.forEach(el => {
    const flattenElement = Object.entries(el)
    const langObj = flattenElement.find(innerElement => innerElement[1].path === route)
    if(langObj){
      routeLanguage = langObj[0]
    }
  })
  console.log('linkObject', links)
  console.log('routeLanguage', routeLanguage)
  if(!routeLanguage) return "/cookies-policy"

  const linkObject = links.find(el => el[routeLanguage].path === route)

  return `${linkObject[targetLanguage].path}${subPage}`
}


class HamburgerMenu extends Component {

  state = {
    mobileMenuIsOpen: false
  }

  openMobileMenu = (bool) => {
    this.setState({mobileMenuIsOpen: bool})
    this.props.toggleOverflow(bool)
    // if(!bool){
    //   window.scrollTo({
    //     top: 0,
    //     behavior: 'smooth'
    //   });
    // }
  }

  onHigherWidth = () => {
    if(this.state.mobileMenuIsOpen && window.innerWidth > 680){
      this.setState({mobileMenuIsOpen: false})
      if(this.state.mobileMenuIsOpen){
        this.props.toggleOverflow()
        window.removeEventListener("resize", this.onHigherWidth);
      }
    }
  }

  render(){
    const { links, lang, changeLanguage } = this.props;
    const { mobileMenuIsOpen } = this.state;


    window.addEventListener("resize", this.onHigherWidth);
  
    const mappedLinks = links
      .map(outerElement => {
          if(outerElement.inner){
            return (
              <VerticalRolloverWrapper linkHeight={LINK_HEIGHT}>{
                [
                  <StyledSpan>{outerElement.label}</StyledSpan>,
                  ...outerElement.inner.map(innerElement => <StyledLink onClick={() => this.openMobileMenu(!mobileMenuIsOpen)} to={`${outerElement.path}${innerElement.path}`}>{innerElement.label}</StyledLink>)
                ]
              }
              </VerticalRolloverWrapper>
            )
          }
          return <StyledLink onClick={() => this.openMobileMenu(!mobileMenuIsOpen)} to={outerElement.path}>{outerElement.label}</StyledLink>
        })

    return (
      <MainWrapper>
        <HamburgerMenuWrapper>
          <Hamburger
            showMobileMenu={mobileMenuIsOpen}
            handleNavToggle={() => this.openMobileMenu(!mobileMenuIsOpen)}
            closeNav={() => this.openMobileMenu(false)}
          />
          <HamburgerLinksContainer isOpen={mobileMenuIsOpen} containerHeight={LINK_HEIGHT * 7 }>
          {
              [...mappedLinks, <LanguageLinkWrapper>
                <LanguageElement
                  isActive={() => lang === "en"}
                  onClick={() => {changeLanguage("en")}}
                  to={loc => {
                    const splitedPathname = loc.pathname.split("/")
                    const targetLink = splitedPathname[1].length > 1 ? generateLink(loc, splitedPathname, "en") : "/"
                    return targetLink
                  }}
                >EN</LanguageElement>
                <LanguageElement
                  isActive={() => lang === "es"}
                  onClick={() => {changeLanguage("es")}}
                  to={loc => {
                    const splitedPathname = loc.pathname.split("/")
                    const targetLink = splitedPathname[1].length > 1 ? generateLink(loc, splitedPathname, "es") : "/"
                    return targetLink
                  }}
                >ES</LanguageElement>
                {/*<LanguageElement*/}
                {/*  isActive={() => lang === "pl"}*/}
                {/*  onClick={() => {changeLanguage("pl")}} */}
                {/*  to={loc => {*/}
                {/*    const splitedPathname = loc.pathname.split("/")*/}
                {/*    const targetLink = splitedPathname[1].length > 1 ? generateLink(loc, splitedPathname, "pl") : "/"*/}
                {/*    return targetLink*/}
                {/*  }}*/}
                {/*>PL</LanguageElement>*/}


              </LanguageLinkWrapper>]
            }
          </HamburgerLinksContainer>
        </HamburgerMenuWrapper>
      </MainWrapper>
    )
}

}

export default HamburgerMenu