import React, { Component } from 'react'
import { withRouter } from "react-router-dom";

import GalleryCategories from './GalleryCategories'
import SpecificWork from './SpecificWork'

import Heading from 'components/Heading'
import {fetchData, mapFetchedLinks, mapFetchedPhotos} from "../functions";

class Work extends Component {
  state = {
    galleryCategories: this.props.galleryCategories || [],
    photos: this.props.allPhotos || []
  };

  componentDidMount = async () => {
    if(this.state.galleryCategories.length === 0 && this.state.photos.length === 0){
      const [photosResp, categoriesResp ] = await Promise.all([
        fetchData('https://dev.platero.eu/wp-json/acf/v3/obras/?per_page=300&page=1'),
        fetchData('https://dev.platero.eu/wp-json/wp/v2/categories/')
      ]);

      const photos = mapFetchedPhotos(photosResp);
      const galleryCategories = mapFetchedLinks(categoriesResp)
        .sort((a,b) => a.order - b.order);

      console.log('photos', photos);
      console.log('galleryCategories', galleryCategories)
      this.setState({
        photos,
        galleryCategories
      })
    }
  };

  render(){
    const category = this.props.match.params.category;
    const { toggleLightbox, all, title, lang } = this.props;
    const { galleryCategories } = this.state;
    const allPhotos = this.state.photos;

    return (!category && all) ?
      <><Heading>{title}</Heading><GalleryCategories lang={lang} categories={galleryCategories} /></>
    :
      galleryCategories.length !== 0 && allPhotos.length !== 0 ?
        <SpecificWork
          toggleLightbox={toggleLightbox}
          allPhotos={allPhotos}
          id={category}
          galleryCategories={galleryCategories}
          lang={lang}
        />
      :
        <span>sdsdsd</span>
  }
}

export default withRouter(Work)
