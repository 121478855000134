import React                            from 'react';
import {
  BrowserRouter,
  Switch,
  Link,
}                                       from 'react-router-dom'
import styled                           from 'styled-components'
import {
  fetchData,
  mapFetchedPhotos,
  mapFetchedLinks,
  mapFetchedExhibitions,
  mapFetchedVideos,
  mapFetchedLiterature, mapFetchedNews,
  convertNewsToExhibition
} from 'functions'

import Header                           from 'components/Header'


import { GlobalStyle }                  from 'styledComponents'
import media                            from 'styledComponents/media'
import Footer                           from 'components/Footer'
import Routes                           from "./routes"

import CookiesTab                       from "components/CookiesTab"

import links                            from './links'

const PagesWrapper = styled.div`
  opacity: ${props => props.isHidden ? 0 : 1};
  display: ${props => props.isHidden ? "none" : "block"};
  max-width: 1200px;
  width: auto;
  margin: 10px auto;
  overflow: hidden;
  
`;

const StyledFooter = styled.footer`
  font-weight: 300;
  margin: 30px 0;
  font-size: 12px;
`;

const HeaderWrapper = styled.div`
  position: sticky;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  background: white;
`;

const MainWrapper = styled.div`
  margin: 0 20px;
  ${media.mobile`
    margin: 0 30px;
  `}
`;


class App extends React.Component {
  state = {
    isLightboxOpen: false,
    overflowIsHidden: false,
    cookiesAccepted: !!localStorage.getItem('cookiesAccepted'),
    lang: localStorage.getItem('lang') || "en",
    translatedLinks: [],
    photos: [],
    galleryCategories: [],
    exhibitions: [],
    videos: [],
    literature: [],
    news: []
  };

  changeLanguage = (lang) => {
    const translatedLinks = this.translateLinks(lang)
    this.setState({ lang, translatedLinks })
    localStorage.setItem('lang', lang);
  };

  translateLinks = (lang) => links.map(linkElement => linkElement[lang])

  componentDidMount(){
    const { photos, galleryCategories, exhibitions, videos, literature, news } = this.state;

    const selectedLanguage = this.state.lang
    const translatedLinks = this.translateLinks(selectedLanguage);

    window.scrollTo(0,0);

    if(photos.length === 0){
      this.getPhotos()
    }
    if(galleryCategories.length === 0){
      this.getLinks()
    }
    if(exhibitions.length === 0){
      this.getExhibitions()
    }
    if(videos.length === 0){
      this.getVideos()
    }
    if(literature.length === 0){
      this.getLiterature()
    }
    if(news.length === 0){
      this.getNews()
    }

    this.setState({ translatedLinks })
  }

  getLang = () => this.state.lang;

  acceptCookies = () => {
    this.setState({cookiesAccepted: true});
    window.localStorage.setItem("cookiesAccepted", 1)
  };

  toggleLightbox = () => {
    const isLightboxOpen = !this.state.isLightboxOpen;
    this.setState({ isLightboxOpen })
  };

  toggleOverflow = (bool) => {
    this.setState({ overflowIsHidden: bool })
  };

  getPhotos = async () => {
    const data = await fetchData('https://dev.platero.eu/wp-json/acf/v3/obras/?per_page=300&page=1')
    const photos = await mapFetchedPhotos(data);
    this.setState({
      photos
    })
  };


  getLinks = () => {
    fetchData('https://dev.platero.eu/wp-json/wp/v2/categories/')
      .then(response => {
        const galleryCategories = mapFetchedLinks(response);
        this.setState({
          galleryCategories: galleryCategories.sort((a,b) => a.order - b.order)
        })
      })
  };

  getLiterature = async () => {
    const data = await fetchData('https://dev.platero.eu/wp-json/wp/v2/literature/');
    const literature = mapFetchedLiterature(data);
    this.setState({ literature })
  }

  getExhibitions = () => {
    fetchData('https://dev.platero.eu/wp-json/wp/v2/exhibitions/')
      .then(response => {
        const rawExhibitions = mapFetchedExhibitions(response);
        const exhibitions = rawExhibitions
          .sort((a,b) => b.sortValue - a.sortValue)
          .sort((a,b) => a.order - b.order);

        this.setState({
          exhibitions
        })
      })
  };

  getNews = () => {
    fetchData('https://dev.platero.eu/wp-json/wp/v2/news/')
      .then(response => {
        const rawNews = mapFetchedNews(response);
        const news = convertNewsToExhibition(rawNews)
        this.setState({
          news: news.sort((a,b) => a.order - b.order)
        })
      })
  }

  getVideos = async () => {
    const data = await fetchData('https://dev.platero.eu/wp-json/wp/v2/videos/');
    const rawVideos = await mapFetchedVideos(data);
    const videos = rawVideos.sort((a,b) => a.order - b.order);
    this.setState({
      videos
    })
  };


  render(){
    const { lang } = this.state;
    const { overflowIsHidden, translatedLinks, isLightboxOpen, cookiesAccepted } = this.state;

    return (
      <BrowserRouter>
        <GlobalStyle lightboxOpen={overflowIsHidden} />
        {!isLightboxOpen &&
          <HeaderWrapper>
            <Header
              toggleOverflow={this.toggleOverflow}
              changeLanguage={this.changeLanguage}
              translatedLinks={translatedLinks}
              allLinks={links}
              lang={lang}
            />
          </HeaderWrapper>
        }
        <Switch>
          <MainWrapper>
            <PagesWrapper overflowIsHidden={overflowIsHidden} isHidden={overflowIsHidden}>
              <Routes {...this.state} toggleLightbox={this.toggleLightbox} />
              {!isLightboxOpen &&
                <>
                  <Footer lang={lang} />
                  <StyledFooter> &copy; Platero 2009 - 2020 Copyrights reserved | designed by Brandwell | developed by Pixdiver | <Link to="/cookies-policy"><span style={{color: "#585c5e", textDecoration: "underline"}}>{lang === "en" ? "Privacy Policy" : "Política de privacidad"}</span></Link></StyledFooter>
                </>
              }
              {!cookiesAccepted && <CookiesTab acceptCookies={this.acceptCookies} />}
            </PagesWrapper>
          </MainWrapper>
        </Switch>
      </BrowserRouter>
    );
  }
}

export default App;
