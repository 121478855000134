import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import media from 'styledComponents/media'
import { Link } from 'react-router-dom'

import MainCard from 'components/MainCard'
import ShowMoreButton from 'components/ShowMoreButton'

import { misc } from 'lang'

const CategoriesWrapper = styled.div`
  opacity: 1;
  transition: opacity 2s ease-in;
  z-index: 1;
  display: inline-grid;
  width: calc(100%);
  grid-template-columns: 1fr;

  a:nth-child(5), a:nth-child(6) {
      display: ${props => props.isMoreContent ? "none" : "block"};
    }

  ${media.mobile`
    grid-template-columns: 1fr 1fr;
  `}
  ${media.desktop`
    grid-template-columns: 1fr 1fr 1fr;
    a:nth-child(5), a:nth-child(6) {
      display: block;
    }
  `}
  grid-gap: 30px;
`

const generateLink = (loc, id, translatedLink) => {
  const route = loc.pathname.split("/")
  if(route[1].length > 0){
    return `${route[1]}/${id ? id : ""}`
  }else{
    return `${translatedLink.path}/${id ? id : ""}`
  }
}

const GalleryCategories = ({ categories, home, translatedLink, lang }) => {
  const [loaded, setLoaded] = useState(false)
  const isMoreContent = home && categories.length > 4;
  const filteredCategories = isMoreContent ? categories.slice(0, 6) : categories;

  useEffect(() => {
    setLoaded(true)
  },[])

  return (
    <CategoriesWrapper id="testtest" isLoaded={loaded} isMoreContent={isMoreContent}>
      {
        filteredCategories.map(el => (
          <Link
            onClick={() => {window.scrollTo(0,0)}}
            to={(loc) => generateLink(loc, el.id, translatedLink)}
          >
            <MainCard
              isWork
              labelPhoto={el.labelPhotos.thumbnail}
              name={el[lang].title}
            />
          </Link>
          )
        )
      }
      {
        home && <ShowMoreButton categoriesCount={categories.length} link={translatedLink.path} isWork>{misc.showMore[lang]}</ShowMoreButton>
      }
    </CategoriesWrapper>
  )
}

export default GalleryCategories
