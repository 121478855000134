export default [
  {
    en:{
      label: "Work",
      path: "/work"
    },
    es:{
      label: "Obras",
      path: "/obras"
    },
    pl:{
      label: "Prace",
      path: "/prace"
    }
  },
  {
    en: {
      label: "Exhibitions",
      path: "/exhibitions",
    },
    es: {
      label: "Exposiciones",
      path: "/exposiciones",
    },
    pl: {
      label: "Wystawy",
      path: "/wystawy",
    },
  },
  {
    en: {
      label: "News",
      path: "/news",
    },
    es: {
      label: "Noticias",
      path: "/noticias",
    },
    pl: {
      label: "Aktualności",
      path: "/aktualnosci",
    },
  },
  {
    en: {
      label: "Video",
      path: "/video",
    },
    es: {
      label: "Vídeo",
      path: "/video",
    },
    pl: {
      label: "Video",
      path: "/video",
    },
  },
  {
    en: {
      label: "Literature",
      path: "/literature",
    },
    es: {
      label: "Literatura",
      path: "/literatura",
    },
    pl: {
      label: "Literatura",
      path: "/literatura",
    },
  },
  {
    en: {
      label: "Bio",
      path: "/bio",
    },
    es: {
      label: "Bio",
      path: "/bio",
    },
    pl: {
      label: "Bio",
      path: "/bio",
    },
  },
]