import React, { Component }             from 'react'
import styled from 'styled-components'
import Heading                          from 'components/Heading'
import GalleryCategories                from 'pages/GalleryCategories'
import Exhibitions                      from 'components/Exhibitions'
import Videos                           from 'components/Videos'
import FullscreenImage                  from './FullscreenImage'

import Progress                         from 'components/Progress'

const MyWrap = styled.div`
  width: 100vw;
  height: 500px;
  position: absolute;
  top: 0;
  left: 0;
  background: red;
  z-index: 2;
  opacity: .3;
`

class Home extends Component {


  render(){
    const { galleryCategories, exhibitions, videos, translatedLinks=[], lang, news } = this.props;

    if(translatedLinks.length === 0) return null;

    return (
      <>
        <FullscreenImage />

        <Heading home margin="60px 0 30px">{translatedLinks[0].label}</Heading>
          {
            galleryCategories.length === 0 ?
              <Progress /> :
              <GalleryCategories lang={lang} categories={galleryCategories} home translatedLink={translatedLinks[0]} />
          }

        <Heading home margin="60px 0 30px">{translatedLinks[1].label}</Heading>
          {
            exhibitions.length === 0 ?
              <Progress />
            :
              <Exhibitions lang={lang} exhibitions={exhibitions} home translatedLink={translatedLinks[1]} />
          }

        <Heading home margin="60px 0 30px">{translatedLinks[2].label}</Heading>
          {
            news.length === 0 ?
              <Progress />
            :
              <Exhibitions lang={lang} exhibitions={news} home translatedLink={translatedLinks[2]} />
          }

        <Heading centered home margin="60px 0 30px">{translatedLinks[3].label}</Heading>
          <Videos home videos={videos} translatedLink={translatedLinks[3]} lang={lang} />

      </>
    )
  };
}

export default Home
