import React, { Component } from 'react'
import { withRouter } from "react-router-dom";
import Vimeo from '@u-wave/react-vimeo';
import styled from 'styled-components'
import { CommingSoon } from "styledComponents"
import { misc } from "lang"
import Videos from 'components/Videos'

import Heading from 'components/Heading'
import { fetchData, mapFetchedVideos} from "../functions";

const VideoWrapper = styled.div`
  max-width: 720px;
  margin: 0 auto;
`;

const Paragraph = styled.p`
  font-size: 17px;
  color: #5A5857;
  font-weight: 500;
  margin-bottom: 20px;
`;

class Video extends Component {

  state = {
    videos: this.props.videos || []
  };

  componentDidMount = async () => {
    if(this.state.videos.length === 0){
      const data = await fetchData('https://dev.platero.eu/wp-json/wp/v2/videos/');
      const rawVideos = await mapFetchedVideos(data);
      const videos = rawVideos.sort((a,b) => a.order - b.order);
      this.setState({
        videos
      })
    }
  };


  render(){
    const { videos } = this.state;
    const { lang, home } = this.props;
    const category = this.props.match.params.category;

    const vid = category && videos.find(el => el.vimeo_movie_id === category);

    if(videos.length === 0){
      return <CommingSoon>{misc.commingSoon[lang]}</CommingSoon>
    }

    return (!category) ?
      <><Heading>Videos</Heading><Videos lang={lang} videos={videos} /></>
    :
      <>
        {vid && <Heading>{vid[lang].title}</Heading>}

        <VideoWrapper home={home}>
          {vid && vid[lang].description && <Paragraph>{vid[lang].description}</Paragraph>}
          <Vimeo
            video={category}
            responsive
          />
        </VideoWrapper>
      </>
  }
}

export default withRouter(Video)
