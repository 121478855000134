import React, { useState }      from 'react'
import styled                   from 'styled-components'
import media                    from 'styledComponents/media'

import AwesomeSlider            from 'react-awesome-slider'

import 'react-awesome-slider/dist/styles.css';

import arrow2 from 'assets/Arrow_1.svg'
import closeButton from 'assets/close-button.svg'


const Slide = styled.div`
  position: ${props => props.stickToBottom ? 'relative' : 'relative'}; //was static
  color: #222;
  background: white;
  min-width: 270px;
  height: 100vh;
  width: 100vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledImage = styled.img`
  max-width: calc(100vw - 40px);
  max-height: ${props => props.viewport.height && props.viewport.height - 40 + "px"};
  ${media.mobile`
    max-width: calc(100vw - 60px);
    max-height: ${props => props.viewport.height && props.viewport.height - 60 + "px"};
  `}
`

const PaintingDesctiptionContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  background: white;
  opacity: 1;
  bottom: 20px;
  width: calc(100vw - 40px);
  margin: 0 auto;
  padding: 30px 5px 40px;
  ${media.mobile`
    padding: 15px 40px;
    width: calc(100vw - 60px);
  `}
  font-size: 18px;
  font-weight: 300;
  transform: translateY(${props => props.isDescriptionOpen ? '0' : '100%'});
  transition: transform 0.4s cubic-bezier(${props => props.isDescriptionOpen ? '0,.89,.18,1' : '.54,0,1,.42'});
  &:before{
    content: "";
    top: 75px;
    position: absolute;
    width: 100vw;
    height: 100px;
    background: white;
  }

  @media (orientation: landscape) and (max-width: 700px){
    display: none;
  }
`

const Properties = styled.div`
  width: 100%;
  ${media.mobile`
    width: calc(100% - 100px);
    margin: 0 20px;
  `}
  text-align: left;
  font-size: 15px;
`

const CloseButton = styled.div`
  font-size: 40px;
  color: #A4A9AD;
  text-align: center;
  position: absolute;
  right: -30px;
  top: -40px;
  z-index: 2;
  width: 45px;
  height: 45px;
  cursor: pointer;
  transform: translateY(${props => props.isDescriptionOpen ? '0' : '-100%'}) translateX(-20px) scale(.8);
  transition: transform 0.4s cubic-bezier(${props => props.isDescriptionOpen ? '0,.89,.18,1' : '.54,0,1,.42'});
  :hover{
    color: #666;
  }
`

const NavButton = styled.div`
  justify-content: center;
  width: 60px;
  height: 60px;
  cursor: ${props => props.disabled ? 'default' : 'pointer'};
  ${props => props.left && `transform: rotate(180deg)`};
  display: none;
  background: white;
  ${media.mobile`
    display: flex;
  `};
  
`

const ArrowIcon = styled.img`
  opacity: ${props => props.disabled ? 0.3 : 1};
  
`

const Val = styled.span`
  position: relative;
  font-family: 'Barlow', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  text-align: left;
  margin: 5px 0;
  font-weight: ${props => props.bold ? 600 : 500};
  color: ${props => props.grey ? "#a6a9a8": "#393c44"};
  :after{
    ${props => props.line && `
      content: "";
      position: absolute;
      width: 200px;
      top: -7px;
      left: 0;
      border-top: 2px solid #a6a9a8;
    `}
  }
`

const SliderContainer = styled.div`
  position: fixed;
  top: 30px;
  left: 20px;
  width: calc(100vw - 40px);
  height: ${props => props.viewport.height && props.viewport.height - 40 + "px"};
  
  &:after{
  content: "";
  top: -80px;
  left: -20px;
  width: 100vw;
  height: 100px;
  background: white;
  position: absolute;
  }

  ${media.mobile`
    left: 30px;
    width: calc(100vw - 60px);
    height: ${props => props.viewport.height && props.viewport.height - 60 + "px"};
  `}
  /* overflow: ${props => props.isOpen ? "hidden" : "visible"}; */
  
  z-index: 3;
`

const getDimensions = () => ({
    height: window.innerHeight,
    width: window.innerWidth
  })



const Carousel = ({ isOpen, closeCarousel, selected, photos, assignPhotoIndex, onNextClick, onPrevClick, lang }) => {
  const [viewportDimension, setViewportDimension] = useState(getDimensions())
  const [isDescriptionOpen, setDescrptionState] = useState(true)

  function resizer(){
    window.removeEventListener("resize", resizer)
    setTimeout(() => {
      setViewportDimension(getDimensions())
    }, 300)
  }

  window.addEventListener("resize", resizer);


  const checkRatio = imageRatio => {
    const viewportRatio = viewportDimension.width / viewportDimension.height
    return (viewportRatio / imageRatio) < 1
  }

  window.addEventListener("keyup", function(event){
    window.onkeydown = function (event) {
      console.log('event', event)
      if(event.code === "ArrowRight"){
        onNextClick()
      }
      if(event.code === "ArrowLeft"){
        onPrevClick()
      }
      if(event.code === "Escape"){
        closeCarousel()
      }
    }
  });

  const toggleDescription = () => setDescrptionState(!isDescriptionOpen);

  if(!isOpen) return null;

  return (
      <SliderContainer viewport={viewportDimension} isOpen={isOpen}>
        <AwesomeSlider organicArrows={false} infinite={true} selected={selected} fillParent bullets={false}>
          {
            photos.map((element, index) => {
              return (
              <>
                <Slide key={index} stickToBottom={checkRatio(element.width / element.height)}>
                  <div><StyledImage onClick={toggleDescription} viewport={viewportDimension} src={element.src} /></div>
                  <PaintingDesctiptionContainer isDescriptionOpen={isDescriptionOpen}>
                      <NavButton left={true} onClick={onPrevClick}><ArrowIcon src={arrow2} /></NavButton>
                      <Properties>
                        <Val bold line>{element.title[lang]}</Val>
                        <Val bold grey>,&nbsp;{element.year} {element.technique[lang]} - {element.basePanel[lang]}({element.infoWidth} x {element.infoHeight} cm) </Val>
                      </Properties>
                      <NavButton onClick={onNextClick}><ArrowIcon src={arrow2} /></NavButton>
                  </PaintingDesctiptionContainer>
                </Slide>
              </>
            )})
          }
        </AwesomeSlider>
        <CloseButton onClick={closeCarousel} isDescriptionOpen={isDescriptionOpen}><img src={closeButton} /></CloseButton>
      </SliderContainer>
  )}

export default Carousel


// <TempWrapper></TempWrapper>
// <TempWrapper><Prop>Title</Prop><Val>{element.title}</Val></TempWrapper>
// <TempWrapper><Prop>Dimensions</Prop><Val>{element.infoWidth} x {element.infoHeight} cm</Val></TempWrapper>
// <TempWrapper><Prop>Technique</Prop><Val>{element.technique}</Val></TempWrapper>
// <TempWrapper><Prop>Year</Prop><Val>{element.year}</Val></TempWrapper>